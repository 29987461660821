import { Component, Input } from '@angular/core';
import { MediaCaptureError } from './media-capture-error';

@Component({
   selector: 'ig-media-capture-error',
   template: `<strong *ngIf="error">{{error.message}}</strong>`
})
export class MediaCaptureErrorComponent {
   @Input() error: MediaCaptureError;
}
