import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhotoCaptureComponent } from './photo-capture.component';
import { MediaCaptureService } from './media-capture.service';
import { MediaCaptureErrorComponent } from './media-capture-error.component';

@NgModule({
   imports: [
      CommonModule
   ],
   exports: [
      PhotoCaptureComponent
   ],
   declarations: [
      PhotoCaptureComponent,
      MediaCaptureErrorComponent
   ],
   providers: [
      MediaCaptureService
   ]
})
export class MediaCaptureModule {
}
