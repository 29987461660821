import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  data = '';

  public onCapture(imageBase64: string): void {
    this.data = imageBase64.substring(0, 1000) + '...';
  }
}
