export class MediaCaptureError {
   type: MediaCaptureErrorType;
   message = '';

   constructor(type: MediaCaptureErrorType) {
      this.type = type;

      switch (type) {
         case MediaCaptureErrorType.CameraAccessNotSupported:
            this.message = 'Camera not suppoted';
            break;
         case MediaCaptureErrorType.CameraConnectFailed:
            this.message = 'Unable to access your camera'
      }
   }
}

export enum MediaCaptureErrorType {
   CameraAccessNotSupported = 0,
   CameraConnectFailed = 1,
}
