import { Component, ElementRef, OnInit, OnDestroy, Output, Renderer2, ViewChild, EventEmitter } from '@angular/core';
import { MediaCaptureService } from './media-capture.service';
import { MediaCaptureError } from './media-capture-error';

@Component({
   selector: 'ig-photo-capture',
   template: `
      <ig-media-capture-error [error]="error"></ig-media-capture-error>

      <video [hidden]="canvasImageSource" #video class="vid" autoplay playsinline></video>

      <div *ngIf="!canvasImageSource">
         <button class="btn btn-primary" (click)="capture()">Capture Image</button>
      </div>

      <canvas [hidden]="!canvasImageSource" #canvas class="vid"></canvas>

      <div *ngIf="canvasImageSource">
         <button class="btn btn-primary" (click)="confirm()">Confirm</button>
         <button class="btn btn-primary" (click)="resetCanvas()">Reset</button>
      </div>
   `,
   styleUrls: [
      './photo-capture.component.scss'
   ]
})
export class PhotoCaptureComponent implements OnInit, OnDestroy {

   @Output() captured: EventEmitter<any> = new EventEmitter();

   @ViewChild('video', {static: true}) videoElement: ElementRef<HTMLVideoElement>;
   @ViewChild('canvas', {static: true}) canvas: ElementRef<HTMLCanvasElement>;

   videoWidth = 0;
   videoHeight = 0;

   isPlaying = false;
   playUnlistener: () => void;

   // Canvas
   public canvasContext: CanvasRenderingContext2D;
   public canvasImageSource: CanvasImageSource;

   public error: MediaCaptureError;

   constructor(private mediaCaptureService: MediaCaptureService,
               private renderer: Renderer2) {
   }

   ngOnInit(): void {
      this.setCanvasContext();
      this.resetCanvas(false);
      this.startCamera();
   }

   ngOnDestroy(): void {
      if (this.playUnlistener) {
         this.playUnlistener();
      }
   }

   public startCamera(): void {
      this.mediaCaptureService
         .startCamera()
         .subscribe(this.attachVideo.bind(this), this.handleError.bind(this))
   }

   handleError(error: MediaCaptureError) {
      this.error = error;
   }

   public attachVideo(stream: MediaStream): void {
      this.renderer.setProperty(this.videoElement.nativeElement, 'srcObject', stream);
      this.listenToVideoPlay();
      this.isPlaying = true;
   }

   public listenToVideoPlay(): void {
      this.playUnlistener = this.renderer.listen(this.videoElement.nativeElement, 'play', (event) => {
         this.videoHeight = this.videoElement.nativeElement.videoHeight;
         this.videoWidth = this.videoElement.nativeElement.videoWidth;
      });
   }

   public capture(): void {
      this.setCanvasSize();
      this.storeImage();
      this.drawImage();
      this.pauseVideo();
   }

   public fullScreen(): void {
      if (this.videoElement.nativeElement.requestFullscreen) {
         this.videoElement.nativeElement.requestFullscreen();
      }
   }

   public setCanvasSize(): void {
      this.renderer.setProperty(this.canvas.nativeElement, 'width', this.videoWidth);
      this.renderer.setProperty(this.canvas.nativeElement, 'height', this.videoHeight);
   }

   public storeImage(): void {
      this.canvasImageSource = this.videoElement.nativeElement;
   }

   public unstoreImage(): void {
      this.canvasImageSource = undefined;
   }

   public drawImage(): void {
      this.canvasContext.drawImage(this.canvasImageSource, 0, 0);
   }

   public pauseVideo(): void {
      this.videoElement.nativeElement.pause();
      this.isPlaying = false;
   }

   public playVideo(): void {
      this.videoElement.nativeElement.play().then(() => {
         this.isPlaying = true;
      });
   }

   public confirm(): void {
      this.emitImage();
   }

   public resetCanvas(triggerPlay = true): void {
      this.canvasContext.clearRect(0, 0, this.videoWidth, this.videoHeight);
      this.renderer.setProperty(this.canvas.nativeElement, 'width', 0);
      this.renderer.setProperty(this.canvas.nativeElement, 'height', 0);
      this.unstoreImage();

      if (!this.isPlaying && triggerPlay) {
         this.playVideo();
      }
   }

   public emitImage(): void {
      this.captured.emit(this.canvas.nativeElement.toDataURL());
   }

   public setCanvasContext(): void {
      this.canvasContext = this.canvas.nativeElement.getContext('2d');
   }
}
