import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MediaCaptureError, MediaCaptureErrorType } from './media-capture-error';

@Injectable()
export class MediaCaptureService {

   constructor() {
   }

   public cameraIsSupported(): boolean {
      return !!(navigator && navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
   }

   public startCamera(): Observable<MediaStream | MediaCaptureError> {
      const constraints: MediaStreamConstraints = {
         video: {
            facingMode: 'environment',
            width: {ideal: 4096},
            height: {ideal: 2160}
         }
      };

      if (this.cameraIsSupported()) {
         return new Observable((observable) => {
            navigator.mediaDevices.getUserMedia(constraints)
               .then((stream) => {
                  observable.next(stream);
                  observable.complete();
               })
               .catch(() => {
                  observable.error(new MediaCaptureError(MediaCaptureErrorType.CameraConnectFailed));
                  observable.complete();
               });
         })
      }

      return new Observable((observable) => {
         observable.error(new MediaCaptureError(MediaCaptureErrorType.CameraAccessNotSupported));
         observable.complete();
      })
   }
}
